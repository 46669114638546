<script lang="ts">
import { getProjectMeta } from '~/util'

export const project = defineProject({
  slug: 'startup-camp',
  primary_color: '#0083CA',
  secondary_color: '#F7B696',
  header: {
    background: {
      image: `/projekt/startup-camp/elbworx-projekt-startup_camp-header-2842.webp`,
    },
    project: 'StartUp Camp',
    project_class: 'text-elbworx-black',
    headline: 'Energieboost für Gründer',
    info_card: {
      description: 'Nordisches Gründer-Camp mit Reise ins Silicon Valley als Hauptpreis',
      client: 'Land Schleswig-Holstein',
      roles: ['Veranstaltungsdesign', 'Kampagne', 'Corporate Design'],
      project_duration: '2018 bis 2022',
    },
  },
})
</script>

<script setup lang="ts">
const { projectPath } = getProjectMeta()
</script>

<template>
  <Project :project="project">
    <template #header_text>
      <h3 class="!text-primary">Die Herausforderung</h3>

      <p>
        Das Land Schleswig-Holstein hat einen Initialzünder für Start-ups ins Leben gerufen: Für das
        <i>StartUp Camp</i>
        können sich deutsche und skandinavische Gründer bewerben, um an einem (jährlich wechselnden)
        Ort in Schleswig-Holstein von erfahrenen Mentoren gecoacht zu werden. Die Gewinner reisen
        dann für ein dreimonatiges Stipendium ins Silicon Valley!
      </p>
      <p>
        Entsprechend eng ist die Entwicklung der visuellen Identität des Events mit
        Standort-Überlegungen verknüpft: Wenn die Golden Gate Bridge plötzlich die Kieler Förder
        oder die Elbe bei Wedel überspannt, wird schnell klar: Das nächste StartUp Camp steht vor
        der Tür! Als die Veranstaltung 2020 zu einem Bestandteil des
        <i>Waterkant Festival</i>
        wurde, galt es auch, dessen Corporate Identity schlüssig in das Erscheinungsbild zu
        integrieren.
      </p>
      <p>
        Seit 2018 statten wir das Camp mit einem Komplettpaket aus, das alle benötigten Digital- und
        Printmedien enthält. Die stringente Präsenz auf allen Kanälen trägt wesentlich zur
        reibungslosen Durchführung der Veranstaltung bei.
      </p>
    </template>

    <Grid class="items-end">
      <div class="col-span-4">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-startup_camp-teilnehmerausweise-1280.webp`"
        />
      </div>
      <Padding only="right" class="col-span-8">
        <Image class="w-full" :src="`${projectPath}elbworx-projekt-startup_camp-willkommen.webp`" />
      </Padding>
    </Grid>
    <Grid class="items-start">
      <div class="col-span-5">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-startup_camp-urkunde-1280.webp`"
        />
      </div>
      <div class="col-span-7 bg-secondary px-16 py-5">
        <Image
          class="w-full"
          :src="`${projectPath}elbworx-projekt-startup_camp-facebook-1920.webp`"
        />
      </div>
    </Grid>

    <Tilt contain class="bg-primary">
      <Padding class="mb-20 mt-8">
        <Text class="text-right text-5xl font-semibold italic sm:mb-10">
          <h2 class="!text-white">„From Waterkant to Silicon Valley“</h2>
        </Text>
        <Image
          class="h-full w-full object-contain px-8"
          :src="`${projectPath}elbworx-projekt-startup_camp-ipad-1280-erik.webp`"
        />
      </Padding>
    </Tilt>

    <ImageSwiper
      class="mt-16"
      :space-between="16"
      :images="[
        {
          url: `${projectPath}elbworx-projekt-startup-camp-sc20_keyvisual-1920.webp`,
          alt: 'StartUp Camp 2020',
        },
        {
          url: `${projectPath}elbworx-projekt-startup-camp-sc21_keyvisual-1920.webp`,
          alt: 'StartUp Camp 2021',
        },
        {
          url: `${projectPath}elbworx-projekt-startup-camp-sc22_keyvisual-1920.webp`,
          alt: 'StartUp Camp 2022',
        },
      ]"
    />
    <Padding class="mt-12 flex flex-col items-center gap-8">
      <div class="max-w-md">
        <Image class="w-full object-cover" :src="`${projectPath}startupcamp-rakete.svg`" />
      </div>
      <Text class="text-center">
        <h4 class="!text-primary">
          „Die Entwicklung der visuellen Identität des Events ist eng mit Standort-Überlegungen
          verknüpft.“
        </h4>
      </Text>
    </Padding>
  </Project>
</template>
